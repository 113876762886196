// <<< DO NOT EDIT GENERATED FILES DIRECTLY! model-store-fields-all.mustache >>>


import instantWinTile from './InstantWinTileZq';

const instantWinAllOf = this;

export default {
  objectType: 'InstantWin_allOf',
  allowableValuesKeys: [],
  constraints: ['isMap',],
  getter: null,
  hiddenFields: [],
  searchableFields: [],
  searchableAllFields: [],
  translatableFields: [],
  sortableFields: [],
  customFieldsFieldName: [],
  metadataFieldName: [],
  tagFieldName: [],
  entityLinksFrom: [],

  route: {


  },
  fieldList: ['name','description','termsAndConditions','constraints','statusCode','instantWinType','playedCount','active','tiles','startDate','endDate','version','style'],
  fields: {
    name: {
      name: 'name',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    description: {
      name: 'description',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    },
    constraints: {
      name: 'constraints',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isContainer','isArray',
                    'isString', ],
      formType: null,
    },
    statusCode: {
      name: 'statusCode',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isShort',
                     ],
      formType: null,
    },
    instantWinType: {
      name: 'instantWinType',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    playedCount: {
      name: 'playedCount',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isInteger','isUnboundedInteger',
                     ],
      formType: null,
    },
    active: {
      name: 'active',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isBoolean',
                     ],
      formType: null,
    },
    // icon: {
    //   name: 'icon',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // banner: {
    //   name: 'banner',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // bannerLowResolution: {
    //   name: 'bannerLowResolution',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    // bannerHighResolution: {
    //   name: 'bannerHighResolution',
    //   complexType: null,
    //   displayOrder: 2000,
    //   allowableValuesKeys: [],
    //   constraints: ['isPrimitiveType','isString',
    //                  ],
    //   formType: null,
    // },
    tiles: {
      name: 'tiles',
      complexType: instantWinTile,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['required','isContainer','isArray',
                    'isModel', ],
      formType: null,
    },
    startDate: {
      name: 'startDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    endDate: {
      name: 'endDate',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isDateTime',
                     ],
      formType: null,
    },
    version: {
      name: 'version',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isNumeric','isLong',
                     ],
      formType: null,
    },
    style: {
      name: 'style',
      complexType: null,
      displayOrder: 2000,
      allowableValuesKeys: [],
      constraints: ['isPrimitiveType','isString',
                     ],
      formType: null,
    }
  }
};
